<template>
  <div>
    <div>
      <div class="card mb-4">
        <div class="card-header"></div>
        <div class="card-body">
          <h4>Filtrage</h4>
          <div class="row" style="margin-top: 30px">
            <div class="col-12 col-lg-4">
              <h6>Recherche :</h6>
              <b-form-input
                placeholder="Recherche par nom ou prénom de client"
                v-model="searchQuery"
              />
            </div>

            <div class="col-12 col-lg-3" style="margin-top: 23px">
              <div class="row">
                <button
                  class="col-6 col-lg-6 btn btn-primary ml-1"
                  @click="SearchQuery()"
                >
                  Filtrer
                </button>

                <button
                  class="col-4 col-lg-4 btn btn-secondary ml-1"
                  @click="clearSearchQuery()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-table
        :items="listClients"
        :fields="fields"
        striped
        hover
        responsive
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Loading...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="redirectToDetail(data.item.id)"
            />
            <b-tooltip
              title="Voir plus"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        class="mt-3"
        align="center"
        v-model="currentPage"
        :total-rows="totalClients"
        :per-page="per_page"
        @change="handlePageChange"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import {
  BTable,
  BTooltip,
  BRow,
  BSpinner,
  BFormInput,
  BPagination,
} from "bootstrap-vue";
import axios from "axios";
import websiteURL from "@/websiteURL";

export default {
  components: {
    BTable,
    BTooltip,
    BRow,
    BSpinner,
    BFormInput,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          label: "Nom",
          key: "last_name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Prénom",
          key: "first_name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Identifiant",
          key: "username",
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Email",
          key: "email",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      isBusy: false,
      listClients: [],
      searchQuery: null,
      currentPage: 1,
      per_page: 10,
      totalClients: 0,
    };
  },
  methods: {
    async redirectToDetail(item) {
      this.$router.push({
        name: "Detail Client",
        params: { id: item },
      });
    },
    //Permet de filtrer les clients
    async SearchQuery() {
      this.currentPage = 1;
      this.fetchClients(this.searchQuery);
    },
    //Permet de réinitialiser le filtre
    async clearSearchQuery() {
      this.searchQuery = null;
      this.currentPage = 1;
      this.fetchClients(this.searchQuery);
    },
    handlePageChange(event) {
      if (event === null) {
        console.log("No page found");
      } else {
        this.currentPage = event;
        this.fetchClients(this.searchQuery);
      }
    },
    //Permet de récuperer la liste des clients
    async fetchClients(searchQuery) {
      (this.listClients = []), (this.isBusy = true);
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/customers";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;

      const params = {
        per_page: this.per_page,
        page: this.currentPage,
      };
      if (searchQuery) {
        params.search = searchQuery;
      }

      try {
        const response = await axios.get(apiUrl, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
          params,
        });
        this.listClients = response.data;
        this.totalClients = response.headers["x-wp-total"];
        this.isBusy = false;
      } catch (error) {
        alert(
          "Une erreur s'est produite, veuillez réessayer plus tard, merci:",
          error
        );
        this.listClients = [];
        this.isBusy = false;
      }
    },
  },
  async created() {
    this.fetchClients();
  },
};
</script>
